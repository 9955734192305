<section class="jumbotron text-center space-top">
    <h1 class="jumbotron-heading">{{labels[settings.language].dashboard}}</h1>
</section>

<div class="row">
    <div *ngFor="let app of apps" class="col-md-3">
        <div class="card mb-4 box-shadow">
            <div class="card-body">
                <a routerLink="/detail/{{app.id}}">{{app.name}}</a>
            </div>
        </div>
    </div>
</div>