import { Component, OnInit } from '@angular/core';

import { App } from '../../../configuration/interfaces/app';
import { AppService } from '../../services/app.service';
import SETTINGS from "../../../configuration/data/settings";
import LABELS from "../../../configuration/data/labels";

@Component({
  selector: 'yp-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.css']
})
export class AppsComponent implements OnInit {
  apps: App[];
  settings: any = SETTINGS;
  labels: any = LABELS;

  constructor(private appService: AppService) { }

  ngOnInit() {
    this.getApps();
  }

  getApps(): void {
    this.appService.getApps()
    .subscribe(apps => this.apps = apps);
  }
}