import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { App } from '../../configuration/interfaces/app';
import { APPS } from '../../configuration/data/apps';
import { MessageService } from './message.service';

@Injectable({ providedIn: 'root' })
export class AppService {

  constructor(private messageService: MessageService) { }

  getApps(): Observable<App[]> {
    this.messageService.add('AppService: fetched apps');
    return of(APPS);
  }

  getApp(id: number): Observable<App> {
    this.messageService.add(`AppService: fetched app id=${id}`);
    return of(APPS.find(app => app.id === id));
  }
}