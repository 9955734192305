<div *ngIf="app" class="space-top">
    <section class="jumbotron text-center">
        <h1 class="jumbotron-heading">{{app.name | uppercase}} Details</h1>
    </section>

    <ul class="list-group">
        <li class="list-group-item d-flex justify-content-between align-items-center">
            {{app.name}}
            <span class="badge badge-primary badge-pill">{{app.id}}</span>
        </li>
    </ul>

    <div class="card">
        <div class="card-body">
            <h5 class="card-title">{{app.name}}</h5>
            <p class="card-text"></p>
            <a [href]="app.link" class="btn btn-primary">{{app.downloadable? "Download": "Open"}}</a>
            <button (click)="goBack()" class="btn btn-secondary">Go back</button>
        </div>
    </div>

    <!--<div>
        <label>Name:
            <input [(ngModel)]="app.name" placeholder="name" />
        </label>
    </div>-->
</div>