import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { App } from '../../../configuration/interfaces/app';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'yp-app-detail',
  templateUrl: './app-detail.component.html',
  styleUrls: ['./app-detail.component.css'],
  encapsulation: ViewEncapsulation.Emulated // None, ShadowDom
})
export class AppDetailComponent implements OnInit {
  app: App;

  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.getHero();
  }

  getHero(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.appService.getApp(id)
      .subscribe(app => this.app = app);
  }

  goBack(): void {
    this.location.back();
  }
}