import { Component, Input, OnInit } from '@angular/core';
import { App } from '../../../configuration/interfaces/app';
import { AppService } from '../../services/app.service';
import SETTINGS from "../../../configuration/data/settings";
import LABELS from "../../../configuration/data/labels";

@Component({
  selector: 'yp-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.css' ]
})
export class DashboardComponent implements OnInit {
  apps: App[] = [];
  settings: any = SETTINGS;
  labels: any = LABELS;

  constructor(private appService: AppService) { }

  ngOnInit() {
    this.getApps();
  }

  getApps(): void {
    this.appService.getApps()
      .subscribe(apps => this.apps = apps);
  }
}