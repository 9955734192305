import { Component, OnInit, OnChanges, OnDestroy, Input } from "@angular/core";

@Component({
    selector: "yp-header",
    templateUrl: "./header.component.html"
})
class HeaderComponent implements OnInit, OnChanges, OnDestroy {
    @Input() title: string;
    @Input() settings: any;
    @Input() labels: any;

    constructor() { }

    ngOnInit(): void { }

    ngOnChanges(): void { }

    ngOnDestroy(): void { }
}

export default HeaderComponent;