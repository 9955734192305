import { Component, OnInit } from '@angular/core';
import SETTINGS from "./configuration/data/settings";
import LABELS from "./configuration/data/labels";

@Component({
  selector: 'yp-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title: string = 'Your Platform';
  settings: any = SETTINGS;
  labels: any = LABELS;

  ngOnInit(): void {
  }
}
