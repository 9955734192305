import { Component, OnInit, OnChanges, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { MessageService } from "../../services/message.service";

import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
//import 'ol/ol.css';

@Component({
    selector: 'yp-maps',
    templateUrl: './maps.component.html',
    styleUrls: ['./maps.component.css', '../../../../../node_modules/ol/ol.css'],
    encapsulation: ViewEncapsulation.None
})
class MapsComponent implements OnInit, OnChanges, OnDestroy {
    map: Map;

    constructor(private messageService: MessageService, private location: Location) { }

    ngOnInit(): void {
        this.messageService.add("Maps: started");

        this.map = new Map({
            layers: [
                new TileLayer({
                    source: new OSM(),
                })],
            target: 'map',
            view: new View({
                center: [0, 0],
                zoom: 2,
            }),
        });
    }

    ngOnChanges(): void {
        this.messageService.add("Maps: changes");
    }

    ngOnDestroy(): void {
        this.messageService.add("Maps: closed");
    }

    goBack(): void {
        this.location.back();
    }
}

export default MapsComponent;