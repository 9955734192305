import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { AppsComponent } from '../pages/apps/apps.component';
import { AppDetailComponent } from '../pages/app-detail/app-detail.component';
import TestComponent from '../pages/test/test.component';
import MapsComponent from '../pages/maps/maps.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'detail/:id', component: AppDetailComponent },
  { path: 'apps', component: AppsComponent },
  { path: 'test', component: TestComponent },
  { path: 'maps', component: MapsComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}