import { Component, OnInit, OnChanges, OnDestroy, Input } from '@angular/core';
import { MessageService } from "../../services/message.service";

@Component({
    selector: 'yp-test',
    templateUrl: './test.component.html'
})
class TestComponent implements OnInit, OnChanges, OnDestroy {

    constructor(private messageService: MessageService) { }

    ngOnInit(): void {
        this.messageService.add("Test: started");
    }

    ngOnChanges(): void {
        this.messageService.add("Test: changes");
    }

    ngOnDestroy(): void {
        this.messageService.add("Test: closed");
    }
}

export default TestComponent;