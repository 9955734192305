import { App } from "../interfaces/app";

export const APPS: App[] = [
    { id: 1, name: "Shop Manager", link: "http://hagik.altervista.org/u/apps/shop-manager.jar", downloadable: true },
    { id: 2, name: "Modedt", link: "http://hagik.altervista.org/u/apps/modedt.jar", downloadable: true },
    { id: 3, name: "Middle Tract Battle", link: "http://hagik.altervista.org/u/apps/middle-tract-battle.jar", downloadable: true },
    { id: 4, name: "Advanced Calculator", link: "http://hagik.altervista.org/u/apps/advanced-calculator.jar", downloadable: true },
    { id: 5, name: "Browser", link: "http://hagik.altervista.org/u/apps/hagik.jar", downloadable: true },
    { id: 6, name: "Analog Clock", link: "http://hagik.altervista.org/u/apps/analog-clock", downloadable: false },
    { id: 7, name: "Paint", link: "http://hagik.altervista.org/u/apps/paint", downloadable: false },
    { id: 8, name: "List Manager", link: "http://hagik.altervista.org/u/apps/list-manager", downloadable: false },
    { id: 9, name: "Webcam", link: "http://hagik.altervista.org/u/apps/webcam", downloadable: false },
    { id: 10, name: "Webcam Effects", link: "http://hagik.altervista.org/u/apps/webcam-effects", downloadable: false },
    { id: 11, name: "Maps", link: "maps", downloadable: false }
];