<header>
    <nav class="navbar navbar-expand-md navbar-dark bg-dark">
        <a class="navbar-brand" routerLink="/">{{title}}</a>

        <div class="navbar-default">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/dashboard">{{labels[settings.language].dashboard}}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/apps">{{labels[settings.language].apps}}</a>
                </li>
                <li class="nav-item" *ngIf="settings.test_mode">
                    <a class="nav-link" routerLink="/test">Test</a>
                </li>
            </ul>
        </div>
    </nav>
</header>