const LABELS: any = {
    eng: {
        apps: "Apps",
        dashboard: "Dashboard"
    },
    ita: {
        apps: "Applicazioni",
        dashboard: "Pannello"
    },
};

export default LABELS;