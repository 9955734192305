import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from '../../app.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { AppDetailComponent } from '../pages/app-detail/app-detail.component';
import { AppsComponent } from '../pages/apps/apps.component';
import { MessagesComponent } from '../utils/messages/messages.component';
import HeaderComponent from '../utils/header/header.component';
import MapsComponent from '../pages/maps/maps.component';

import { AppRoutingModule } from './app-routing.module';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  declarations: [
    AppComponent,
    DashboardComponent,
    AppsComponent,
    AppDetailComponent,
    MessagesComponent,
    HeaderComponent,
    MapsComponent
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }